.topSection {
  margin-block: 2.25rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.addOpportunity {
  display: flex;
  align-items: center;
  background-color: #eaf4ff;
  padding: 2rem;
  border-radius: 10px;
  font-size: 14px;
  color: #273c97;
  font-weight: bold;
  width: fit-content;
  position: relative;
  cursor: pointer;
}

.guideSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.65rem;
  padding: 0.75rem;
  border-radius: 1rem;
  background: #f7f9fb;
}

.statusGuide {
  display: flex;
  gap: 0.25rem;
}

.red {
  height: 1rem;
  width: 1rem;
  background: #ff4c4c;
}

.yellow {
  height: 1rem;
  width: 1rem;
  background: #daa520;
}

.green {
  height: 1rem;
  width: 1rem;
  background: #4caf50;
}

.guideSection h2 {
  color: #000000;
  font-weight: 600;
  font-size: 1rem;
}

.guideSection h3 {
  color: #000000;
  font-weight: 400;
  font-size: 0.875rem;
}

.addIcon {
  background-color: white;
  padding: 4px !important;
  border-radius: 20px;
  color: #273c97 !important;
  font-size: 28px !important;
}

.addIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assets/Ellipse\ 376.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  position: absolute;
  top: -19px;
  left: 34%;
}

.addOpportunityText {
  max-width: 180px;
  color: #6c6c6c;
}

/* Filter Dropdown */

.filterBtnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 0.75rem;
  margin-inline: 1rem;
}

.filterDropdown {
  width: fit-content;
  border: 1px solid #ddd;
  padding: 10px 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #fff;
  color: #273c97;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-block: 1rem;
}

.arrowDown {
  margin-left: 5px;
  font-size: 12px;
}

.filterOptions {
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 5px;
  z-index: 1000;
}

.filterOptions p {
  padding: 10px 20px;
  margin: 0;
  cursor: pointer;
  color: #273c97;
}

.filterOptions p:hover {
  background-color: #f0f0f0;
}

.jobCard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: calc(50% - 20px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardHeader {
  margin-left: auto;
  width: fit-content;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 4px;
}
.cardHeader > div {
  font-size: 1rem;
}
.cardDetails {
  color: #828282;
  margin-bottom: 15px;
}

.cardDetails p {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cardDescription {
  color: #828282;
}

.readMore {
  color: #273c97;
  font-weight: bold;
  cursor: pointer;
}

.viewLinkButton {
  background-color: #273c97;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.commentsBtn {
  background-color: #ffffff;
  color: #273c97;
  border: none;
  padding: 10px 20px;
  border-radius: 0.55rem;
  cursor: pointer;
  text-align: center;
  margin-top: 1rem;
  border: 1px solid #273c97;
}

.commentsBtnSection {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.commentsBtnSection button {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 10px;
  width: 25%;
}

.viewLinkButton:hover {
  background-color: #273c97;
}

.statusOpen {
  background-color: #d6ecff !important;
  color: #006bb4 !important;
  border: 1px solid #006bb4;
  padding: 0px 0px;
  border-radius: 50%;
  font-weight: bold;
}

.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10 !important;
}

.statusPassed {
  background-color: #d4f7d4 !important;
  color: #0a9a0a !important;
  padding: 0px 0px;
  border-radius: 50%;
  border: 1px solid #0a9a0a;
  font-weight: bold;
}

.inReview {
  background-color: #ffeb3b;
  /* Yellow for "In Review" */
  color: black;
  font-weight: bold;
}

.reviewed {
  background-color: #4caf50;
  /* Green for "Reviewed" */
  color: white;
  font-weight: bold;
}

.rejected {
  background-color: #f44336;
  /* Red for "Rejected" */
  color: white;
  font-weight: bold;
}

.selectRoot {
  min-width: 120px;
  /* Set a width for the select */
  border-radius: 4px;
}

.JobSelect:focus {
  border: none !important;
}

.statusTextInReview {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border: 2px solid #daa520;
  color: #daa520;
  padding: 0.6rem;
  border-radius: 5px;
}

.statusTextAccepted {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border: 2px solid #4caf50;
  color: #4caf50;
  padding: 0.6rem;
  border-radius: 5px;
}

.statusTextRejected {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border: 2px solid #ff4c4c;
  color: #ff4c4c;

  padding: 0.6rem;
  border-radius: 5px;
}

/* media */

@media screen and (max-width: 40rem) {
  .topSection {
    flex-direction: column;
    align-items: center;
  }
  .guideSection {
    width: 100% !important;
  }
  .jobCards {
    flex-direction: column;
  }
  .jobCard {
    width: 100%;
  }
}
