.courseVideoLessons {
  padding: 20px;
  color: #333;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
}

.lessonList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.lessonItem {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.lessonItem:hover {
  background-color: #f3f3f3;
}

.icon {
  font-size: 1.2em;
  margin-right: 10px;
  color: #273c97;
}

.lessonInfo {
  display: flex;
  flex-direction: column;
}

.lessonTitle {
  font-size: 1em;
  font-weight: bold;
  margin: 0;
}

.lessonDuration {
  font-size: 0.9em;
  color: #777;
}

.activeLesson {
  background-color: #e9e5ff;
}

.activeLesson .icon {
  color: #273c97;
}

.activeLesson .lessonTitle {
  color: #273c97;
}

@media screen and (max-width: 40rem) {
  .courseVideoLessons {
    padding: 0px !important;
  }

  .courseContainer {
    padding: 0px !important;
  }
}
