.accordionQAContainer {
  width: 100%;
  padding: 1.25rem 2rem;
  background: #f5f5f5;
  border-radius: 10px;
}

.accordionQA {
  padding: 0.25rem;
  border-radius: 0.5rem !important;
  margin-bottom: 0.75rem;
}

.accordionHeader {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  width: 100%;
}

.accordionHeader h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
}

.disabledAccordion {
  pointer-events: none;
}

.disabledSummary {
  cursor: default;
}
@media screen and (max-width: 40rem) {
  .selectQA {
    margin-top: 10px;
    margin-left: 0px !important;
    /* margin: auto; */
  }
}
